// Responsive
$small-mobile-width: 'only screen and (max-width : 360px)';
$mobile-width: 'only screen and (max-width : 576px)';
$tablet-width: 'only screen and (max-width : 768px)';
$desktop-width: 'only screen and (max-width : 992px)';

html {
  // background-color: #92bfb8;
}

.full-screen {
  margin: 0;
  padding: 0;
}

.general-button-properties {
  // Style
  border: none;
  border-radius: 50px;
  padding: 15px;
  font-family: 'Inter';
  font-size: 22px;
  font-weight: 600;

  // Positioning
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  @media #{$mobile-width} {
    top: 85%;
  }
}

.start-button {
  background-color: white;
  border: 4px solid #fbe8a6;
  color: #31496e;

  &:hover {
    cursor: pointer;
    background-color: #fbe8a6;
  }
}

.stop-button {
  background-color: #fbe8a6;
  border: 4px solid #fbe8a6;
  color: #31496e;

  &:hover {
    cursor: pointer;
    background-color: #92bfb8;
    border: 4px solid #92bfb8;
  }
}

.canvas {
  transform: scaleX(-1);
  filter: FlipH;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  object-fit: cover;
}

.data {
  // Style
  border: none;
  border-radius: 50px;
  padding: 15px;
  font-family: 'Inter';
  font-size: 22px;
  font-weight: 400;
  background-color: #fbe8a6;
  color: #31496e;
  cursor: none;
  white-space: nowrap;

  // Positioning
  position: absolute;
  top: 15%;
  z-index: 99;

  @media #{$mobile-width} {
    top: 10%;
  }
}

.time {
  // Positioning
  left: 10%;

  @media #{$mobile-width} {
    left: 5%;
  }
}

.counter {
  // Positioning
  right: 10%;
  @media #{$mobile-width} {
    right: 5%;
  }
}

.general-text-properties {
  // Style
  font-family: 'Inter';
  font-size: 58px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  white-space: pre-line;

  // Positioning
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  @media #{$mobile-width} {
    font-size: 50px;
    width: 95%;
  }
}

.inactive-text {
  color: #31496e;
}

.yellow-background {
  background-color: #fbe8a6;
}

.orange-background {
  background-color: #ff8e55;
}

.green-background {
  background-color: #92bfb8;
}

.loading-image {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  object-fit: cover;
}

.loading-text {
  color: #ff8e55;
}

.loading-button {
  background-color: #92bfb8;
  color: #31496e;
  z-index: 100;
  cursor: default;
  white-space: nowrap;
}

.loading-canvas {
  z-index: -99;
}
