// Responsive
$small-mobile-width: 'only screen and (max-width : 360px)';
$mobile-width: 'only screen and (max-width : 576px)';
$tablet-width: 'only screen and (max-width : 768px)';
$desktop-width: 'only screen and (max-width : 992px)';

#root {
  //   background-color: #92bfb8;
}

.container {
  // Flexbox
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.content {
  // Style
  width: 90%;
  max-width: 1100px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}

.header {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #31496e;
}

.form-control {
  border-style: none none solid;
  border-width: 2px;
  border-color: #000 #000 #fbe8a6;
}

.error {
  border-style: none none solid;
  border-width: 2px;
  border-color: #000 #000 #dd6c38;
}

.error-message {
  color: #dd6c38;
}

.submit-button {
  margin-top: 10px;
  padding: 15px;
  border-style: none;
  border-radius: 50px;
  background-color: #fbe8a6;
  color: #31496e;

  &:hover {
    text-decoration: none;
    background-color: #92bfb8;
    color: #31496e;
  }
}

.link {
  color: #92bfb8;

  &:hover {
    color: #fbe8a6;
    text-decoration: none;
  }
}

.redirect-text {
  margin-top: 15px;
  color: #92bfb8;
}

.primary-text {
  // Style
  font-family: 'Inter';
  font-size: 58px;
  font-weight: 600;
  color: #31496e;
  line-height: 1.3;
  text-align: center;
  white-space: pre-line;

  // Positioning
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  @media #{$mobile-width} {
    font-size: 50px;
    width: 95%;
  }
}

.signup-button {
  margin-top: 10px;
  padding: 15px;
  border-style: none;
  border-radius: 50px;
  background-color: #fbe8a6;
  color: #31496e;

  &:hover {
    text-decoration: none;
    background-color: #92bfb8;
    color: #31496e;
  }

  @media #{$mobile-width} {
    color: #92bfb8;
    background-color: transparent;
    margin-top: 0px;
    padding: 0px;
    border-style: none;
    border-radius: 0px;

    &:hover {
      color: #fbe8a6;
      text-decoration: none;
    }
  }
}

.yellow-background {
  background-color: #fbe8a6;
}

.orange-background {
  background-color: #ff8e55;
}

.green-background {
  background-color: #92bfb8;
}
